<!-- Satın Alma Geçmişinde , Geçmişte Satın Alınan Ürünleri Temsil Edecek Card Bileşeni  -->
<template>
  <b-card
no-body
      class="card-statistics rounded"
  >
    <b-card-header class="border shadow">
      <b-card-title class="product-title"><b class="text-primary">{{ $t('Name Of The Product') }}:</b> <br><span
          class="light-title font-small-4">{{ product.title }}</span></b-card-title>
      <b-card-title class="product-title"><b class="text-primary">{{ $t('History') }}:</b> <br><span
          class="light-title font-small-4">{{ product.created_format }}</span></b-card-title>
      <b-card-title class="product-title"><b class="text-primary">{{ $t('Price') }}:</b> <br><span
          class="light-title font-small-4">{{ product.total_amount_price }} ₺</span></b-card-title>
      <b-card-text v-if="false" class="mr-25 mb-0">
        Alınma Tarihi: {{ product.created_format }}
      </b-card-text>
      <div class="d-flex justift-content-center align-items-center details" v-if="isMobile===false">
        <b-media-aside v-b-toggle="product.customer_product_sales_id">
          <b-avatar size="48" variant="light-primary">
            <feather-icon size="24" icon="AlignCenterIcon"/>
          </b-avatar>
        </b-media-aside>
        <span><b class="font-small-4">{{ $t('Details') }}</b></span>
      </div>
      <div class="d-flex justify-content-end align-items-center details mt-2" v-if="isMobile===true">
        <b-media-aside class="toggle-button" v-b-toggle="product.customer_product_sales_id">
          <b-avatar size="24" variant="light-primary">
            <feather-icon size="12" icon="ArrowDownIcon"/>
          </b-avatar>
        </b-media-aside>
        <span class="details-text"><b>{{ $t('Details') }}</b></span>
      </div>
    </b-card-header>

    <b-collapse :id="product.customer_product_sales_id">
      <b-card-body class="statistics-body border" :id="product.customer_product_sales_id">
        <b-row>
          <b-col md="3" sm="6" class="mb-2 mb-md-0">
            <h4 class="font-small-4"><b>{{ $t('Product Details') }}</b></h4>
            <hr class="hr-custom">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="ArchiveIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.description }}</h4>
                <b-card-text class="font-small-3"> {{ $t('Description') }}</b-card-text>
              </b-media-body>
            </b-media>
            <b-media no-body class="mt-2">
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="CalendarIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.created_format }}</h4>
                <b-card-text class="mb-0 font-small-3"> {{ $t('History') }}</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0">
            <h4 class="font-small-4"><b>{{ $t('Price Details') }}</b></h4>
            <hr class="hr-custom">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="BarChartIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.quantity }}</h4>
                <b-card-text class="mb-0 font-small-3"> {{ $t('Quantity') }}</b-card-text>
              </b-media-body>
            </b-media>
            <b-media no-body class="mt-2">
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="DollarSignIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.total_amount_price }}₺</h4>
                <b-card-text class="mb-0 font-small-3"> {{ $t('Total Paid Fee') }}</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0">
            <h4 class="font-small-4"><b>{{ $t('Trainer Details') }}</b></h4>
            <hr class="hr-custom">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="UserIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.trainer_name }} </h4>
                <b-card-text class="font-small-3 mb-0"> {{ $t('Trainer') }}</b-card-text>
              </b-media-body>
            </b-media>
             <b-media no-body class="mt-2">
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="UserIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.activity_consumed_count }} / {{product.session}} </h4>
                <b-card-text class="font-small-3 mb-0"> {{ $t('Harcanan') }}</b-card-text>
              </b-media-body>
            </b-media>


          </b-col>
          <b-col md="3" sm="6" class="mb-2 mb-md-0">
            <h4 class="font-small-4"><b>{{ $t('Activity Details') }}</b></h4>
            <hr class="hr-custom">
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="36" variant="light-primary">
                  <feather-icon size="16" icon="WatchIcon"/>
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0 font-small-3">{{ product.activity_name }}</h4>
                <b-card-text class="font-small-3 mb-0"> {{ $t('Activity') }}</b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>

    </b-collapse>
    <b-card-footer class="border shadow pt-1 pb-1">
      <b-row class="justify-content-end">
        <b-col class="col-md-6 col-sm-12 col-xs-12 text-left" >
        <template v-if="product.payment_confirm == false && product.payment_is_canceled_status != 2"> 
             <h4 class="text-danger">Paketinizi etkinleştirmek için lütfen  bizim ile iletişime geçiniz</h4>
        </template>
        </b-col>
        <b-col class="col-md-6 col-sm-12 col-xs-12  text-right">
          <template v-if="product.payment_is_canceled_status == 1">
          <b-button
              size="sm"
              style="min-width:150px"
              class=""
              variant="danger"
              @click="cancelPayment(product.customer_product_sales_id)">
            {{ $t('Cancel Sale') }}
          </b-button>
        </template>

        <template v-if="product.payment_is_canceled_status == 2 ">
          <b-button
              size="sm"
              disabled
              style="min-width:150px"
              class=""
              variant="secondary"
          >
            {{ $t('Sale Canceled') }}
          </b-button>
        </template>
          
        </b-col>
       
   

      </b-row>

    </b-card-footer>

  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  VBToggle
} from 'bootstrap-vue'

export default {
  name: 'PurchaseHistoryCustomerCard',
  props: {
    product: {
      type: Object,
      required: true
    },
  },
  methods: {
  

    cancelPayment(val) 
     {
      this.$swal({
        title: this.$t("Are you sure?"),
        text: this.$t("Siparişi iptal etmek istediğinizden emin misiniz ?"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("No"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
         if (result.isConfirmed == true) {

          this.$database.CustomerProductSaleService.CancelProductSale(val,).then(
              (res) => {
                // console.log(res);
                if (res.IsSuccess == true) 
                {
                  this.$functions.Messages.success("Sale Canceled")
                  this.product.payment_is_canceled_status=2
                } else { 

                    this.$functions.Messages.error("Action failed!");           
                }
              }
          );
        }
      });
    },
  },
  components: {
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BCardFooter,
    BCardHeader,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCollapse,
    VBToggle,
    BAlert
  },
  directives: {
    'b-toggle': VBToggle
  },
}
</script>
