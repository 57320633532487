<template>
  <div>
    <b-alert
        variant="light"
        show
        v-if="customer_sales_products.length >= 1"
    >
      <h4 v-if="$i18n.locale ==='tr'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        Satın alma geçmişinde <b>{{ customer_sales_products.length }}</b> adet ürün bulundu
      </h4>
      <h4 v-if="$i18n.locale ==='en'" class="alert-heading bg-primary bg-lighten-1 text-white rounded">
        {{ customer_sales_products.length }} products found in purchase history
      </h4>
    </b-alert>
    <div
        v-for="(product , index) in customer_sales_products"
        :key="index"
    >
      <PurchaseHistoryCustomerCard :product="product"/>
    </div>


    <template v-if="customer_sales_products.length === 0">
      <b-row class="d-flex justify-content-center align-items-center text-center">
        <p class="make-appointment-title">{{ $t('No Products Found In Purchase History') }}</p>
      </b-row>
      <b-row class="d-flex justify-content-center align-items-center">
        <b-img
            class="select-product-svg"
            src="@/assets/images/badge/purchased-search.svg" fluid></b-img>
      </b-row>
      <b-row class="d-flex justify-content-center">
        <b-button
            variant="primary" class="my-1 d-block"
            :to="{name: 'shop'}">{{ $t('Buy Product') }}
        </b-button>
      </b-row>
      <!-- <b-col>
        <b-alert variant="success" show class="custom-shadow p-1">
          <p class="alert-heading text-primary">
            {{$t('No Products Found In Purchase History')}} !
          </p>

        <b-button
        variant="primary" class="mt-1"
        :to="{name: 'shop'}">{{$t('Buy Product')}}</b-button>
        </b-alert>
      </b-col> -->


    </template>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardFooter,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BCollapse,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BLink,
  BMedia,
  BMediaAside,
  BMediaBody,
  BPagination,
  BRow,
  VBToggle
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import moneyFormat from '@/functions/MoneyFormat'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import PurchaseHistoryCustomerCard from '@/components/PurchaseHistoryCustomerCard/PurchaseHistoryCustomerCard'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle
  },
  components: {
    PurchaseHistoryCustomerCard,
    // BSV
    ToastificationContent,
    BDropdown,
    BDropdownItem,
    BFormRadioGroup,
    BFormRadio,
    BRow,
    BCol,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BCard,
    BCardBody,
    BLink,
    BImg,
    BCardText,
    BButton,
    BPagination,
    BCardFooter,
    BCardHeader,
    BCardTitle,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    BCollapse,
    VBToggle,
    BAlert
  },
  data() {
    return {
      merchant_payment_id:null,
      customer_sales_products: [],
      customer_sales_product: {
        quantit: "",
        unit_price: "",
        tax_rate: "",
        total_price: "",
        discount_rate: "",
        discounted_total_amount: "",
        total_amount_after_tax: "",
        payment_is_canceled:"",
        payment_confirm:"",
        created_date: "",
        updated_date: "",
        title: "",
        description: "",
        name: "",
        activity_color: "",
        last_name: "",
      },
      statisticsItems: [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: "230k",
          subtitle: "Sales",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-info",
          title: "8.549k",
          subtitle: "Customers",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "BoxIcon",
          color: "light-danger",
          title: "1.423k",
          subtitle: "Products",
          customClass: "mb-2 mb-sm-0",
        },
        {
          icon: "DollarSignIcon",
          color: "light-success",
          title: "$9745",
          subtitle: "Revenue",
          customClass: "",
        },
      ],
    };
  },
  methods: {
    dateFormat: (val) => {
      if (val != null) {
        let sonucDeger;
        if (typeof val !== 'undefined') {
          let date = val.split('T')[0].split('-').reverse().join('.')
          let hour = val.split('T')[1].split('+')[0]
          let hourPart = hour.split(':')
          hour = hourPart[0] + ':' + hourPart[1] + ':' + hourPart[2].split('.')[0]
          sonucDeger = date + ' ' + hour
        } else {
          sonucDeger = "Information Not Available"
        }
        return sonucDeger
      } else {
        return "Information Not Available"
      }
    },
    getCustomerProducts() {
      this.$database.PurchasedService.get_customer_products(
          this.$store.getters["auth/userInfo"].person_id,
          this.$store.getters["auth/userInfo"].company_branch_id
      ).then((res) => {

        if (res.IsSuccess === true || res.IsSuccess === 1) {
            if(this.status =="True")
            {
              this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("İşleminiz başarılı bir şekilde gerçekleşti"),
                icon: "CheckIcon",
                variant: "success",
              },
              })
            }
            
            if(this.status =="False")
            {
               this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("İşleminiz gerçekleşmedi"),
                icon: "CrossIcon",
                variant: "danger",
              },
              })
            }
          //this.customer_sales_products = res.result;
          res.Result.CustomerProductSaleViews.map(el => {
             this.customer_sales_products.push({
               //...el,
                customer_product_sales_id:   el.CustomerProductSalesId,
                product_priceId: el.ProductPriceId,
                person_id:  el.PersonId,
                 title:el.ProductName,
                activity_name:el.ActivityName,
                quantity:  el.Quantity,
                session: el.Session,
                activity_consumed_count:el.ActivityConsumedCount,
                unit_price :el.UnitPrice,
                tax_rate:  el.TaxRate,
                total_price : el.TotalPrice,
                trainer_name: el.TrainerName,
                payment_type_id: el.PaymentTypeId,
                discount_rate: el.DiscountRate,
                expiry_date: el.ExpiryDate,              
                created_format: this.$t(this.dateFormat(el.CreatedDate)),
                updated_date:  el.UpdatedDate,
                discounted_total_amount : moneyFormat(el.DiscountedTotalAmount),
                total_amount_price: el.TotalPrice,
                payment_confirm : el.PaymentConfirm,
                payment_is_canceled_status:  el.PaymentIsCanceledStatus
            //   total_amount_price: moneyFormat(el.discounted_total_amount),
            //   created_format: this.$t(this.dateFormat(el.created_date))
            })

            
          })
         
        }
      });
    },
    //  cancelPayment(val,index) 
    //  {
    //   this.$swal({
    //     title: this.$t("Are you sure?"),
    //     text: this.$t("Siparişi iptal etmek istediğinizden emin misiniz ?"),
    //     icon: "warning",
    //     showCancelButton: true,
    //     confirmButtonText: this.$t("Yes"),
    //     cancelButtonText: this.$t("No"),
    //     customClass: {
    //       confirmButton: "btn btn-primary",
    //       cancelButton: "btn btn-outline-danger ml-1",
    //     },
    //     buttonsStyling: false,
    //   }).then((result) => {
    //      if (result.isConfirmed == true) {

    //         this.$database.CustomerProductSaleService.CancelProductSale(val,).then(
    //           (res) => {
    //             // console.log(res);
    //             if (!res.IsSuccess) {
    //               this.$functions.Messages.error("Action failed!");
    //             } else {
    //               // this.$functions.Messages.success(res.Result);
    //               // this.customer_sales_products[index].payment_is_canceled= true;
    //               // this.productData= [];
    //               // this.productBaseData = [];
    //               this.customer_sales_products = [];
    //               this.getCustomerProducts();
    //             }
    //           }
    //         );
    //     }
    //   });
    // },

  },
  created() {
    //MerchantPaymentId kısaltması
    this.merchant_payment_id=this.$route.query.mpid;

    this.status=this.$route.query?.status;

    this.getCustomerProducts();
  },
};
</script>

<!-- stillendirme -->
<style lang="scss" src="@/styles/scss/purchased.scss" scoped> </style>
