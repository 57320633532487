<template>
  <b-card>
    <b-form @submit.prevent>
      <b-row>
        <!-- Email alanı /start -->
        <b-col md="12" sm="12">
          <b-form-group :label="$t('E-Mail')" label-for="email">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="MailIcon" />
              </b-input-group-prepend>
              <b-form-input
                @blur="$v.customerActivateModel.Email.$touch()"
                :class="{ 'is-invalid': $v.customerActivateModel.Email.$error }"
                id="email"
                v-model="customerActivateModel.Email"
                type="email"
                :placeholder="$t('Email')"
              />
            </b-input-group>

            <small
              v-if="isSubmit && !$v.customerActivateModel.Email.required"
              class="form-text text-danger"
              >{{ $t("The field is required") }}</small
            >
            <small
              v-if="isSubmit && !$v.customerActivateModel.Email.email"
              class="form-text text-danger"
              >{{ $t("The email field must be a valid email") }}</small
            >
          </b-form-group>
        </b-col>
        <!-- Email alanı /end -->

        <!-- Password alanı /start -->
        <b-col md="12" sm="12">
          <b-form-group>
            <div class="d-flex">
              <label for="password">{{ $t("Password") }}</label>
            </div>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>
              <b-form-input
                @blur="$v.customerActivateModel.Password.$touch()"
                id="password"
                v-model="customerActivateModel.Password"
                :class="{
                  'is-invalid': $v.customerActivateModel.Password.$error,
                }"
                class="form-control-merge"
                :type="password1FieldType"
                name="password"
                :placeholder="$t('Şifre Giriniz')"
              />
              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password1ToggleIcon"
                  @click="togglePassword1Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="isSubmit && !$v.customerActivateModel.Password.required"
              class="form-text text-danger"
              >{{ $t("The field is required") }}</small
            >
          </b-form-group>
        </b-col>
        <!-- Password alanı /end -->

        <!-- Confirm Password alanı /start -->
        <b-col md="12" sm="12">
          <b-form-group>
            <div class="d-flex">
              <label for="confirm-password">{{ $t("Confirm Password") }}</label>
            </div>
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>
              <b-form-input
                @blur="$v.customerActivateModel.ConfirmPassword.$touch()"
                id="confirm-password"
                :class="{
                  'is-invalid': $v.customerActivateModel.ConfirmPassword.$error,
                }"
                v-model="customerActivateModel.ConfirmPassword"
                class="form-control-merge"
                :type="password2FieldType"
                name="confirm-password"
                :placeholder="$t('Şifreyi Tekrar Giriniz')"
              />

              <b-input-group-append is-text>
                <feather-icon
                  class="cursor-pointer"
                  :icon="password2ToggleIcon"
                  @click="togglePassword2Visibility"
                />
              </b-input-group-append>
            </b-input-group>
            <small
              v-if="
                isSubmit && !$v.customerActivateModel.ConfirmPassword.required
              "
              class="form-text text-danger"
              >{{ $t("The field is required") }}</small
            >
            <small
              v-if="
                isSubmit &&
                  !$v.customerActivateModel.ConfirmPassword.sameAs &&
                  $v.customerActivateModel.ConfirmPassword.required
              "
              class="form-text text-danger"
              >{{ $t("Does not match the password") }}</small
            >
          </b-form-group>
        </b-col>
        <!-- Confirm Password alanı /end -->

        <!-- Onay ve Vazgeç butonları  /start -->
        <b-col cmd="6" sm="12" class="right">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            type="reset"
            variant="outline-primary"
            class="mr-1"
            :to="{ name: 'customerList' }"
          >
            {{ $t("Cancel") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            @click="create"
          >
            {{ $t("Confirm") }}
          </b-button>
        </b-col>
        <!-- Onay ve Vazgeç butonları  /end -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BFormTextarea,
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BInputGroup,
  BInputGroupPrepend,
  BCardText,
  BFormRadio,
  BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapGetters, mapActions } from "vuex";
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import { required, email, sameAs } from "vuelidate/lib/validators";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    Cleave,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BInputGroup,
    BInputGroupPrepend,
    BCardText,
    vSelect,
    mapGetters,
    mapActions,
    BFormTextarea,
    BFormRadio,
    BInputGroupAppend,
  },
  mixins: [togglePasswordVisibility],
  props: {},
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    password2ToggleIcon() {
      return this.password2FieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  data() {
    return {
      customerActivateModel: {
        Email: "",
        Password: "",
        ConfirmPassword: "",
        CompanyBranchId: "",
      },
      isSubmit: false,

      // Toggle Password
      password1FieldType: "password",
      password2FieldType: "password",
    };
  },
  validations: {
    customerActivateModel: {
      Email: {
        required,
        email,
      },
      Password: {
        required,
      },
      ConfirmPassword: {
        required,
        sameAs: sameAs((vm) => {
          return vm.Password;
        }),
      },
    },
  },
  methods: {
    togglePassword1Visibility() {
      this.password1FieldType =
        this.password1FieldType === "password" ? "text" : "password";
    },
    togglePassword2Visibility() {
      this.password2FieldType =
        this.password2FieldType === "password" ? "text" : "password";
    },
    create() {
      this.isSubmit = true;
      if (this.$v.$invalid) return;
      this.$swal({
        title: this.$t("Are you sure?"),
        html: `<b>${this.customerActivateModel.Email}</b> mailine sahip müşteriyi onaylamak istediğinize emin misiniz?`, //this.$t('Are You Sure You Want To Cancel Your Appointment?'),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Yes"),
        cancelButtonText: this.$t("Cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$database.CustomerService.CustomerManualActivate(
            this.customerActivateModel.CompanyBranchId,
            this.customerActivateModel.Email,
            this.customerActivateModel.Password,
            this.customerActivateModel.ConfirmPassword
          ).then((res) => {
            if (res.IsSuccess === true || res.IsSuccess === 1) {
              this.$functions.Messages.success(res.Result);
              this.$router.push({ name: "customerList" });
            } else {
              this.$functions.Messages.error(res.Result);
            }
          });
        }
      });
    },
  },
  watch: {},
  created() {
    this.customerActivateModel.CompanyBranchId = this.$store.getters[
      "auth/userInfo"
    ].company_branch_id;
  },
};
</script>

<style lang="scss" src="@/styles/scss/studioCreate.scss" scoped></style>
